import { Link } from 'react-router-dom';
import { Text } from '../Text/Text';
import { useTheme } from '@mui/material';
import { linkSx, linkTextSx } from './Hyperlink.style';

export const Hyperlink = ({ to, text, color = 'tertiary' }) => {
  const theme = useTheme();

  const baseColor =
    color === 'tertiary'
      ? theme.palette.tertiary.main
      : theme.palette.quaternary.main;

  const hoverColor =
    color === 'tertiary'
      ? theme.palette.quaternary.main
      : theme.palette.tertiary.main;

  return (
    <Link to={to} style={linkSx}>
      <Text
        component="span"
        text={text}
        color={color}
        sx={linkTextSx(baseColor, hoverColor)}
      />
    </Link>
  );
};
