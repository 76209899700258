import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MaterialAccordion,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Text } from '../Text/Text';
import { accordionSx } from './Accordion.style';
import { font500Sx, font900Sx } from '../../styles/Global.style';

export const Accordion = ({ heading, content }) => {
  const theme = useTheme();

  return (
    <MaterialAccordion sx={accordionSx}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: theme.palette.tertiary.main }} />
        }
      >
        <Text
          text={heading}
          color={theme.palette.primary.main}
          sx={font900Sx}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Text
          text={content}
          color={theme.palette.primary.main}
          sx={font500Sx}
        />
      </AccordionDetails>
    </MaterialAccordion>
  );
};
