import { MenuItem, TextField, useTheme } from '@mui/material';
import { formInputSx, inputLabelPropsSx } from '../../styles/Global.style';

export const FormSelect = ({
  selectFor,
  labelText,
  required = true,
  fullWidth = true,
  formik,
  options,
}) => {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      select
      required={required}
      fullWidth={fullWidth}
      id={selectFor}
      value={formik.values[selectFor]}
      onChange={formik.handleChange}
      label={labelText}
      name={selectFor}
      error={Boolean(formik.errors[selectFor])}
      helperText={formik.errors[selectFor]}
      color="tertiary"
      sx={formInputSx(theme)}
      InputLabelProps={inputLabelPropsSx(theme)}
    >
      {options.map((option, i) => (
        <MenuItem key={`${selectFor}-${i}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
