import { Heading } from '../Heading/Heading';
import { Box, useTheme } from '@mui/material';
import { headingSx, contentSx, featureContainerSx } from './Feature.style';
import { Text } from '../Text/Text';

export const Feature = ({ icon: Component, heading, content }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <Box component="div" sx={featureContainerSx(theme)}>
      <Component />

      <Heading
        level={2}
        text={heading}
        color={theme.palette.primary.main}
        sx={headingSx}
      />

      <Text text={content} color={color} sx={contentSx} />
    </Box>
  );
};
