import { breakpoints } from '../themes/breakpoints';
import { pxToRem } from './pxToRem';

export const responsiveFontSizes = ({ xs, sm, md }) => ({
  [`@media only screen and (min-width: ${breakpoints.values.xs}px)`]: {
    fontSize: pxToRem(xs),
  },
  [`@media only screen and (min-width: ${breakpoints.values.sm}px)`]: {
    fontSize: pxToRem(sm),
  },
  [`@media only screen and (min-width: ${breakpoints.values.md}px)`]: {
    fontSize: pxToRem(md),
  },
});
