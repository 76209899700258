import { pxToRem } from '../helpers/pxToRem';
import { responsiveFontSizes } from '../helpers/responsiveFontSizes';

export const typography = {
  fontFamily: 'Montserrat',
  fontSize: 18,
  fontWeight: 900,
  htmlFontSize: 10,

  body1: {
    fontWeight: 600,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs: 16, sm: 16, md: 16 }),
  },

  h1: {
    fontWeight: 700,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ xs: 16, sm: 16, md: 16 }),
  },

  h2: {
    fontWeight: 700,
    fontSize: pxToRem(56),
    ...responsiveFontSizes({ xs: 32, sm: 32, md: 44 }),
  },

  button: {
    fontWeight: 900,
    fontSize: pxToRem(14),
    ...responsiveFontSizes({ xs: 14, sm: 14, md: 14 }),
  },
};
