import { Box, Stack, useTheme } from '@mui/material';
import { Success as SuccessIcon } from '../../icons/Success/Success';
import { containerSx } from '../../styles/Global.style';
import { Heading } from '../Heading/Heading';
import {
  successContentContainerSx,
  successIconSx,
  successTextSx,
  successTitleSx,
} from './Success.style';
import { Text } from '../Text/Text';

export const Success = ({ title, text }) => {
  const theme = useTheme();
  const color = theme.palette.secondary.main;

  return (
    <Stack component="section" sx={containerSx}>
      <Box sx={successContentContainerSx}>
        <SuccessIcon sx={successIconSx} />
        <Heading text={title} level={4} color={color} sx={successTitleSx} />
        <Text text={text} color={color} sx={successTextSx} />
      </Box>
    </Stack>
  );
};
