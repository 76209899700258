import { MDText } from 'i18n-react';

const getNestedValues = (obj, key) => {
  if (!key) {
    return obj;
  }
  const keyParts = key.split('.');
  return getNestedValues(obj[keyParts.shift()], keyParts.join('.'));
};

export const generateCopy = (json) => {
  const mdText = new MDText(json, {
    MDFlavor: 1,
  });

  return {
    ...mdText,
    generate(key) {
      return mdText.translate(key);
    },
    inject(key, obj) {
      return mdText.translate(key, obj);
    },
    generateArray(key, inject) {
      const obj = JSON.parse(
        JSON.stringify(getNestedValues(json, key)),
        (k, v) => {
          return typeof v === 'string' ? mdText.translate(v, inject) : v;
        },
      );
      return Object.keys(obj).map((k) => obj[k]);
    },
  };
};
