import { TextField, useTheme } from '@mui/material';
import { formInputSx, inputLabelPropsSx } from '../../styles/Global.style';

export const FormInput = ({
  inputFor,
  inputType,
  labelText,
  required = true,
  fullWidth = true,
  formik,
  onKeyUp,
  disableLabel,
  inputProps,
  multiline,
  rows,
}) => {
  const theme = useTheme();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      type={inputType}
      required={required}
      fullWidth={fullWidth}
      id={inputFor}
      value={formik.values[inputFor]}
      onChange={formik.handleChange}
      label={labelText}
      name={inputFor}
      error={Boolean(formik.errors[inputFor])}
      helperText={formik.errors[inputFor]}
      color="tertiary"
      sx={formInputSx(theme)}
      InputLabelProps={inputLabelPropsSx(theme, disableLabel)}
      InputProps={inputProps}
      onKeyUp={onKeyUp}
      multiline={multiline}
      rows={rows}
    />
  );
};
