import { useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';

export const About = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('About.intro.title')}
        heading={Copy.inject('About.intro.heading', {
          supporting: dynamicTextColor('Supporting Students.', quaternary),
        })}
        content={Copy.generate('About.intro.content')}
        isColumn
        isTop
      />

      <ContentCard
        title={Copy.generate('About.note.title')}
        heading={Copy.inject('About.note.heading', {
          chair: dynamicTextColor('Charlotte Malone.', quaternary),
        })}
        content={Copy.generate('About.note.content')}
        isColumn
        isSecondaryCard
      />
    </>
  );
};
