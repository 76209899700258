import { Button as Btn } from '@mui/material';
import { buttonSx } from './Button.style';

export const Button = ({
  children,
  text,
  onClick,
  fullWidth = false,
  type,
  sx,
  variant = 'contained',
  ...rest
}) => (
  <Btn
    onClick={onClick}
    type={type}
    fullWidth={fullWidth}
    variant={variant}
    sx={{ ...sx, ...buttonSx }}
    {...rest}
  >
    {children || text}
  </Btn>
);
