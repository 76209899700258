export const successContentContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '350px',
  height: '70%',
  alignItems: 'center',
  textAlign: 'center',
};

export const successIconSx = { marginBottom: '16px' };

export const successTitleSx = { fontWeight: 800, marginBottom: '24px' };

export const successTextSx = {
  fontSize: '18px',
  fontWeight: 500,
};
