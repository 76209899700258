import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    document.getElementById('root')?.scrollTo(0, 0);
  }, [pathname]);

  return children;
};
