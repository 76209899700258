import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { PAYPAL } from '../../constants/links';
import { Fab } from '@mui/material';
import { donateButtonSx } from './DonateButton.style';

export const DonateButton = () => (
  <Fab
    sx={donateButtonSx}
    aria-label="donate"
    onClick={() => window.open(PAYPAL)}
    color="quintenary"
  >
    <VolunteerActivismIcon />
  </Fab>
);
