import { Box, useTheme } from '@mui/material';
import { ContentCard } from '../../components/ContentCard/ContentCard';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Text } from '../../components/Text/Text';
import { Button } from '../../components/Button/Button';
import { buttonSx } from '../../styles/Global.style';
import {
  XMAS_FAMILY,
  XMAS_HAMPER,
  XMAS_INDIVIDUAL,
} from '../../constants/links';

export const Events = () => {
  const theme = useTheme();
  const quaternary = theme.palette.quaternary.main;

  return (
    <>
      <ContentCard
        title={Copy.generate('Events.intro.title')}
        heading={Copy.inject('Events.intro.heading', {
          book: dynamicTextColor('book your spot today.', quaternary),
        })}
        content={Copy.generate('Events.intro.content')}
        isColumn
        isTop
      />

      <ContentCard
        img="/assets/img/xmas.webp"
        title={Copy.generate('Events.xmas.title')}
        heading={Copy.inject('Events.xmas.heading', {
          fayre: dynamicTextColor('Fayre.', theme.palette.quaternary.main),
        })}
        content={Copy.generate('Events.xmas.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Button
            text={Copy.generate('Buttons.santaIndividual')}
            onClick={() => window.open(XMAS_INDIVIDUAL)}
            sx={{ ...buttonSx(theme), width: '100%' }}
          />
        </Box>
        <Box>
          <Button
            text={Copy.generate('Buttons.santaFamily')}
            onClick={() => window.open(XMAS_FAMILY)}
            sx={{ ...buttonSx(theme), width: '100%', marginTop: '12px' }}
          />
        </Box>
        <Box>
          <Button
            text={Copy.generate('Buttons.xmasHamper')}
            onClick={() => window.open(XMAS_HAMPER)}
            sx={{ ...buttonSx(theme), width: '100%', marginTop: '12px' }}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/halloween.webp"
        title={Copy.generate('Events.halloween.title')}
        heading={Copy.inject('Events.halloween.heading', {
          disco: dynamicTextColor(`Disco.`, theme.palette.quaternary.main),
        })}
        content={Copy.generate('Events.halloween.content')}
      >
        <Box>
          <Text
            text={Copy.generate('Events.eventPassed')}
            color={theme.palette.tertiary.main}
          />
        </Box>
      </ContentCard>

      <ContentCard
        img="/assets/img/chippy.webp"
        title={Copy.generate('Events.chippie.title')}
        heading={Copy.inject('Events.chippie.heading', {
          chippie: dynamicTextColor(
            `Chippy Hike.`,
            theme.palette.quaternary.main,
          ),
        })}
        content={Copy.generate('Events.chippie.content')}
        isSecondaryCard
        isReversed
      >
        <Box>
          <Text
            text={Copy.generate('Events.eventPassed')}
            color={theme.palette.tertiary.main}
          />
        </Box>
      </ContentCard>
    </>
  );
};
