import { Text } from '../../components/Text/Text';
import { formErrorSx, formMessageSx } from './Form.style';

export const Form = ({ children, formik, isError, message }) => (
  <form onSubmit={formik.handleSubmit} noValidate>
    {children}
    {message && (
      <Text sx={isError ? formErrorSx : formMessageSx} text={message} />
    )}
  </form>
);
