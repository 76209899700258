import { IconButton } from '@mui/material';

export const IconBtn = ({ children, icon, onClick, color, sx, aria }) => (
  <IconButton
    style={{ color }}
    onClick={onClick}
    sx={sx}
    disableRipple
    aria-label={aria}
  >
    {icon || children}
  </IconButton>
);
