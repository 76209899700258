import React, { useState } from 'react';
import { NavMenuDrawer } from '../../components/NavMenuDrawer/NavMenuDrawer';
import { NavBar } from '../../components/NavBar/NavBar';
import { Box } from '@mui/material';
import { paddingTopSx, pageContainerSx } from './PageContainer.style';
import { Footer } from '../../components/Footer/Footer';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';

export const PageContainer = ({ children }) => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const { isMobile } = useIsMobile();

  const toggle = () => setIsNavMenuOpen(!isNavMenuOpen);

  return (
    <>
      <Box sx={pageContainerSx}>
        <NavBar
          isNavMenuOpen={isNavMenuOpen}
          toggleMenuBtn={toggle}
          isMobile={isMobile}
        />

        {isNavMenuOpen && isMobile ? (
          <NavMenuDrawer toggleDrawer={toggle} sx={paddingTopSx} />
        ) : (
          <Box component="main" sx={paddingTopSx}>
            {children}
          </Box>
        )}
      </Box>

      {!isNavMenuOpen && <Footer />}
    </>
  );
};
