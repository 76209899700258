import { Typography } from '@mui/material';

export const Heading = ({ text, level, sx, color, ...rest }) => (
  <Typography
    variant={`h${level}`}
    sx={{
      color,
      ...sx,
    }}
    {...rest}
  >
    {text}
  </Typography>
);
