import { useState } from 'react';
import { FeedbackForm } from '../../components/FeedbackForm/FeedbackForm';
import { Success } from '../../components/Success/Success';
import { Copy } from '../../copy/Copy';
import { dynamicTextColor } from '../../helpers/dynamicTextColor';
import { Box, useTheme } from '@mui/material';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { useIsMobile } from '../../hooks/useIsMobile/useIsMobile';
import {
  font800Sx,
  marginBottom12Sx,
  marginBottom32Sx,
  splitContainerSx,
  splitContentContainerSx,
  splitFormContainerSx,
} from '../../styles/Global.style';
import { Heading } from '../../components/Heading/Heading';

export const Feedback = () => {
  const theme = useTheme();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { windowSize } = useWindowSize();
  const { isMobile } = useIsMobile();

  const handleSubmit = () => {
    setIsSubmitted(true);
  };

  return (
    <>
      <Box component="section" sx={splitContainerSx(isMobile)}>
        <Box
          component="div"
          sx={splitContentContainerSx(isMobile, windowSize.appInnerHeight)}
        >
          <Box
            component="div"
            className="animate__animated animate__fadeInLeft"
            sx={marginBottom32Sx}
          >
            <Heading
              level={1}
              text={Copy.generate('Global.feedback')}
              color={theme.palette.tertiary.main}
              sx={marginBottom12Sx}
            />

            <Heading
              level={2}
              text={Copy.inject('Feedback.heading', {
                matters: dynamicTextColor(
                  'Your opinion matters.',
                  theme.palette.quaternary.main,
                ),
              })}
              color={theme.palette.secondary.main}
              sx={font800Sx}
            />
          </Box>
        </Box>

        <Box component="div" sx={splitFormContainerSx}>
          {!isSubmitted && <FeedbackForm handleSubmit={handleSubmit} />}

          {isSubmitted && (
            <Success
              title={Copy.generate('Global.thanks')}
              text={Copy.generate('Feedback.success.text')}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
