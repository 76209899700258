import { useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { FormContainer } from '../../containers/FormContainer/FormContainer';
import { Copy } from '../../copy/Copy';
import { marginTop16Sx, buttonSx } from '../../styles/Global.style';
import { Form } from '../Form/Form';
import { FormInput } from '../FormInput/FormInput';
import { Button } from '../Button/Button';
import {
  FEEDBACK_INIT_VALUES,
  FEEDBACK_SELECT_OPTIONS,
} from '../../constants/feedback';
import { FEEDBACK_VALIDATION_SCHEMA } from '../../validation/feedback';
import { FormSelect } from '../FormSelect/FormSelect';
import emailjs from '@emailjs/browser';

export const FeedbackForm = ({ handleSubmit }) => {
  const theme = useTheme();
  const [message, setMessage] = useState();
  const [isError, setIsError] = useState(false);
  const EMAIL = Copy.generate('Inputs.email');
  const FEEDBACK = Copy.generate('Inputs.feedback');

  const resetError = () => {
    setTimeout(() => {
      setMessage('');
      setIsError(false);
    }, 3000);
  };

  const formik = useFormik({
    initialValues: FEEDBACK_INIT_VALUES,
    validationSchema: FEEDBACK_VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (val) => {
      try {
        await emailjs.send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_FEEDBACK_TEMPLATE_ID,
          val,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY,
        );

        handleSubmit();
      } catch (err) {
        setIsError(true);
        setMessage(err.message);
        resetError();
      }
    },
  });

  return (
    <FormContainer
      title={Copy.generate('FeedbackForm.title')}
      className="animate__animated animate__fadeInRight"
    >
      <Form formik={formik} isError={isError} message={message}>
        <FormInput
          inputFor="email"
          formik={formik}
          labelText={EMAIL}
          required={false}
        />
        <FormSelect
          selectFor="feedbackReason"
          formik={formik}
          options={FEEDBACK_SELECT_OPTIONS}
          labelText={Copy.generate('Inputs.feedbackReason')}
        />
        <FormInput
          inputFor="feedback"
          formik={formik}
          labelText={FEEDBACK}
          inputType="textarea"
          multiline
          rows={4}
        />
        <Button
          type="submit"
          fullWidth
          text={Copy.generate('Buttons.submit')}
          sx={{ ...buttonSx(theme), ...marginTop16Sx }}
        />
      </Form>
    </FormContainer>
  );
};
