import * as Yup from 'yup';
import { Copy } from '../copy/Copy';

export const FEEDBACK_VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email(Copy.generate('Errors.invalidEmail')),
  feedbackReason: Yup.string()
    .trim()
    .required(Copy.generate('Errors.required')),
  feedback: Yup.string().trim().required(Copy.generate('Errors.required')),
});
