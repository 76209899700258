import { useTheme } from '@mui/material';
import { Copy } from '../../copy/Copy';
import { Text } from '../Text/Text';
import { taglineSx } from './Tagline.style';

export const Tagline = ({ sx }) => {
  const theme = useTheme();

  return (
    <Text
      text={Copy.generate('Global.tagline')}
      color={theme.palette.secondary.main}
      sx={{ ...taglineSx, ...sx }}
    />
  );
};
