export const linkSx = {
  textDecoration: 'none',
  cursor: 'pointer',
};

export const linkTextSx = (color, hoverColor) => ({
  borderBottom: `solid 2px ${color}`,
  color: color,

  '&:hover': {
    borderBottom: `solid 2px ${hoverColor}`,
    color: hoverColor,
  },
});
