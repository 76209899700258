export const formErrorSx = {
  margin: '15px !important',
  textAlign: 'center',
  color: 'red',
};

export const formMessageSx = {
  margin: '15px !important',
  textAlign: 'center',
};
