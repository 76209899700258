import { Typography } from '@mui/material';

export const Text = ({
  text,
  fontSize,
  variant = 'body1',
  noWrap = false,
  color,
  sx,
  ...rest
}) => (
  <Typography
    variant={variant}
    sx={{
      fontSize,
      color,
      ...sx,
    }}
    noWrap={noWrap}
    {...rest}
  >
    {text}
  </Typography>
);
